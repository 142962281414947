<template>
  <div class="base-input" :class="[className, { 'base-input--password': type === 'password' }]">
    <div
      v-if="inputType !== 'textarea'"
      class="base-input__wrap"
      :class="{
        focused: isFocus,
        'input-error':
          ($v.currentValue && $v.currentValue.$error) || isShowErrorCustom || (isShowError && errorMaxAmount),
        'hidden-label': hiddenLabel,
        'has-value': !isFocus && currentValue !== ''
      }"
    >
      <template v-if="$v.currentValue && $v.currentValue.$error && type !== 'password'" />
      <template v-else>
        <span
          v-if="type === 'password' && isShowPassword"
          class="icon-eye-off"
          @click="!disabled && togglePassword()"
        />
        <span
          v-if="type === 'password' && !isShowPassword"
          class="icon-eye-show"
          @click="!disabled && togglePassword()"
        />
        <p v-else-if="toVnd" class="base-input__vnd">VND</p>
        <p v-else-if="toLink" class="base-input__link">Link</p>
      </template>
      <span
        v-if="type !== 'password' && currentValue && close"
        class="base-input__close icon-close"
        alt="clear"
        @click="!disabled && resetValue()"
      />
      <IMaskComponent
        ref="el"
        v-model.lazy="currentValue"
        :type="['password', 'tel'].includes(inputType) ? inputType : null"
        :mask="getMask()"
        :name="name"
        :maxlength="limitInput"
        :disabled="disabled"
        :placeholder="formatPlaceholder"
        :thousands-separator="thousandsSeparatorSymbol"
        :autocomplete="autocomplete"
        :prepare="onPrepare"
        :lazy="true"
        v-bind="$attrs"
        @blur="onBlur()"
        @focus="onFocus()"
        @keypress="onKeyPress($event)"
      />
      <label v-if="!hiddenLabel">{{ label }}</label>
      <slot />
      <slot name="copy" />
    </div>
    <div
      v-else
      class="base-input__wrap base-input__wrap--reverse"
      :class="{
        focused: isFocus || currentValue,
        'input-error': ($v.currentValue && $v.currentValue.$error) || isShowErrorCustom
      }"
    >
      <label>{{ label }}</label>
      <textarea
        v-model.trim="currentValue"
        :name="name"
        v-bind="$attrs"
        :maxlength="limitInput"
        :placeholder="formatPlaceholder"
        @blur="onBlur()"
        @focus="onFocus()"
      />
      <slot name="copy" />
    </div>
    <slot name="verify" @click="$emit('handle-verify')" />
    <em v-if="$v.currentValue && $v.currentValue.$error" class="error" :class="classError">
      <template v-if="$v.currentValue.required.$invalid">{{ formatErrorRequired }}</template>
      <template v-else-if="$v.currentValue.minLength.$invalid">{{ formatErrorMinLength }}</template>
      <template v-else-if="$v.currentValue.maxLength.$invalid">{{ formatErrorMaxLength }}</template>
      <template v-else-if="$v.currentValue.minValue.$invalid">{{ formatErrorMinValue }}</template>
      <template v-else-if="$v.currentValue.maxValue.$invalid">{{ formatErrorMaxValue }}</template>
      <template v-else-if="$v.currentValue.email.$invalid">{{ formatErrorEmail }}</template>
      <template v-else-if="$v.currentValue.sameAs.$invalid">{{ formatErrorSameAs }}</template>
      <template v-else-if="$v.currentValue.specialCharacter.$invalid">{{ formatErrorSpecicalCharacter }}</template>
      <template v-else-if="$v.currentValue.usernameCharacter.$invalid">{{ formatErrorUsernameCharacter }}</template>
      <template v-else-if="$v.currentValue.phoneCharacter.$invalid">{{ formatErrorPhoneCharacter }}</template>
      <template v-else-if="$v.currentValue.startZero.$invalid">{{ formatErrorStartZero }}</template>
      <template v-else-if="$v.currentValue.fullNameCharacter.$invalid">{{ formatErrorFullNameCharacter }}</template>
      <template v-else-if="$v.currentValue.displayNameCharacter.$invalid">
        {{ formatErrorDisplayNameCharacter }}
      </template>
      <template v-else-if="$v.currentValue.password.$invalid">{{ formatErrorPassword }}</template>
      <template v-else-if="$v.currentValue.isDuplicate.$invalid">{{ formatErrorDuplicate }}</template>
      <template v-else-if="$v.currentValue.validSpaceCharacter.$invalid">{{ formatErrorSpaceCharacter }}</template>
      <template v-else-if="$v.currentValue.validTrc20Wallet.$invalid">{{ formatErrorWallet }}</template>
      <template v-else-if="$v.currentValue.validErc20Wallet.$invalid">{{ formatErrorWallet }}</template>
    </em>
    <slot name="error" />
  </div>
</template>
<script setup lang="ts">
import { IMaskComponent } from 'vue-imask'

import { useVuelidate } from '@vuelidate/core'
import { required, maxLength, minLength, helpers, email } from '@vuelidate/validators'
import isEmpty from 'lodash/isEmpty'
import { REGEX_PATTERNS } from '~/config/constant'
const { $config } = useNuxtApp()

const specialCharacter = helpers.regex(/^[a-zA-Z0-9]*$/)
const usernameCharacter = helpers.regex(/^[a-zA-Z0-9][a-zA-Z0-9.,$;]+$/)
const phoneCharacter = helpers.regex(/^(03|05|07|08|09|01[2|6|8|9])[0-9]{7,8}$/)
const startZero = helpers.regex(/^[0][0-9_]*$/)
const fullNameCharacter = helpers.regex(/^[A-Za-z ]*$/)
const displayNameCharacter = helpers.regex(/^[a-zA-Z0-9_]*$/)
const validPassword = helpers.regex(REGEX_PATTERNS.PASSWORD)
const validSpaceCharacter = helpers.regex(/^[a-zA-Z0-9]*$/)
const validTrc20Wallet = helpers.regex(/^(T)[0-9A-Za-z]{33}$/)
const validErc20Wallet = helpers.regex(/^(0x)?[0-9a-fA-F]{40}$/)
const props = defineProps({
  name: {
    type: String,
    default: ''
  },
  className: {
    type: String,
    default: ''
  },
  toVnd: {
    type: Boolean,
    default: false
  },
  type: {
    type: String,
    default: 'text'
  },
  label: {
    type: String,
    default: ''
  },
  modelValue: {
    type: [String, Number, Array, Object],
    default: null
  },
  placeholder: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  },
  close: {
    type: Boolean,
    default: true
  },
  blurValidate: {
    type: Boolean,
    default: false
  },
  required: {
    type: Boolean,
    default: false
  },
  maxLength: {
    type: Number,
    default: undefined
  },
  minLength: {
    type: Number,
    default: undefined
  },
  maxValue: {
    type: Number,
    default: undefined
  },
  minValue: {
    type: Number,
    default: undefined
  },
  limitInput: {
    type: Number,
    default: undefined
  },
  thousandsSeparatorSymbol: {
    type: String,
    default: ''
  },
  specialCharacter: {
    type: Boolean,
    default: false
  },
  usernameCharacter: {
    type: Boolean,
    default: false
  },
  phoneCharacter: {
    type: Boolean,
    default: false
  },
  errorUsernameCharacter: {
    type: String,
    default: ''
  },
  errorPhoneCharacter: {
    type: String,
    default: ''
  },
  startZero: {
    type: Boolean,
    default: false
  },
  sameAs: {
    type: String,
    default: ''
  },
  classError: {
    type: String,
    default: ''
  },
  errorRequired: {
    type: String,
    default: ''
  },
  errorMinLength: {
    type: String,
    default: ''
  },
  errorMaxLength: {
    type: String,
    default: ''
  },
  errorMinValue: {
    type: String,
    default: ''
  },
  errorMaxValue: {
    type: String,
    default: ''
  },
  errorEmail: {
    type: String,
    default: ''
  },
  errorSpecialCharacter: {
    type: String,
    default: ''
  },
  errorStartZero: {
    type: String,
    default: ''
  },
  errorSameAs: {
    type: String,
    default: ''
  },
  isUpperCase: {
    type: Boolean,
    default: false
  },
  fullNameCharacter: {
    type: Boolean,
    default: false
  },
  errorFullNameCharacter: {
    type: String,
    default: ''
  },
  displayNameCharacter: {
    type: Boolean,
    default: false
  },
  errorDisplayNameCharacter: {
    type: String,
    default: ''
  },
  errorDuplicate: {
    type: String,
    default: ''
  },
  errorSpaceCharacter: {
    type: String,
    default: ''
  },
  maskCustom: {
    type: [Object, String, Array, Function, Boolean],
    default: () => ({})
  },
  allowValidatePassword: {
    type: Boolean,
    default: true
  },
  isDuplicate: {
    type: String,
    default: ''
  },
  notAllowSpace: {
    type: Boolean,
    default: false
  },
  prependIcon: {
    type: String,
    default: ''
  },
  hiddenLabel: {
    type: Boolean,
    default: false
  },
  toLink: {
    type: Boolean,
    default: false
  },
  isCheckDone: {
    type: Boolean,
    default: false
  },
  isShowErrorCustom: {
    type: Boolean,
    default: false
  },
  errorMaxAmount: {
    type: Boolean,
    default: false
  },
  trc20Wallet: {
    type: Boolean,
    default: false
  },
  erc20Wallet: {
    type: Boolean,
    default: false
  },
  errorWallet: {
    type: String,
    default: ''
  },
  autocomplete: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['update:modelValue', 'on-key-press', 'input-focus', 'handle-verify'])

const { $formatMarkToNumber } = useNuxtApp()

const isShowPassword = ref(false)
const isFocus = ref(false)
const inputType = ref(unref(props.type))
const isShowError = ref(false)
const el = ref(null)
const currentValue = computed({
  get() {
    return props.modelValue
  },
  set(value: any) {
    const val = props.modelValue == null && value === '0' ? '' : value
    if (!props.disabled) {
      emit('update:modelValue', val)
    }
  }
})
// Show message validate
const formatErrorWallet = computed(() => {
  return props.errorWallet ? props.errorWallet : 'Địa chỉ ví của bạn không hợp lệ, vui lòng nhập lại'
})

const formatPlaceholder = computed(() => {
  return props.placeholder ? props.placeholder : `Nhập ${props.label ? props.label.toLowerCase() : ''}`
})
const formatErrorRequired = computed(() => {
  return props.errorRequired ? props.errorRequired : `Vui lòng nhập ${props.label ? props.label.toLowerCase() : ''}`
})
const formatErrorMinLength = computed(() => {
  return props.errorMinLength
    ? props.errorMinLength
    : `Vui lòng không nhập ít hơn ${props.minLength} ${
        props.type === 'tel' || props.type === 'number' ? 'chữ số' : 'kí tự'
      }`
})
const formatErrorMaxLength = computed(() => {
  return props.errorMaxLength
    ? props.errorMaxLength
    : `Vui lòng không nhập nhiều hơn ${props.maxLength} ${
        props.type === 'tel' || props.type === 'number' ? 'chữ số' : 'kí tự'
      }`
})
const formatErrorMinValue = computed(() => {
  return props.errorMinValue ? props.errorMinValue : `${props.label} tối thiểu là ${props.minValue}`
})
const formatErrorMaxValue = computed(() => {
  return props.errorMaxValue ? props.errorMaxValue : `${props.label} tối đa là ${props.maxValue}`
})
const formatErrorEmail = computed(() => {
  return props.errorEmail ? props.errorEmail : 'Địa chỉ email không hợp lệ'
})
const formatErrorSameAs = computed(() => {
  return props.errorSameAs ? props.errorSameAs : `${props.label} không trùng khớp`
})
const formatErrorSpecicalCharacter = computed(() => {
  return props.errorSpecialCharacter ? props.errorSpecialCharacter : `${props.label} không chứa các kí tự đặc biệt`
})
const formatErrorUsernameCharacter = computed(() => {
  return props.errorUsernameCharacter ? props.errorUsernameCharacter : `${props.label} không hợp lệ`
})
const formatErrorPhoneCharacter = computed(() => {
  return props.errorPhoneCharacter ? props.errorPhoneCharacter : `${props.label} không hợp lệ`
})
const formatErrorStartZero = computed(() => {
  return props.errorStartZero ? props.errorStartZero : `${props.label} phải bắt đầu với số 0`
})
const formatErrorFullNameCharacter = computed(() => {
  return props.errorFullNameCharacter
    ? props.errorFullNameCharacter
    : `${props.label} không bao gồm số và các kí tự đặc biệt, viết hoa không dấu`
})
const formatErrorDisplayNameCharacter = computed(() => {
  return props.errorDisplayNameCharacter
    ? props.errorDisplayNameCharacter
    : `${props.label} không bao gồm chữ Tiếng Việt, không chứa kí tự đặc biệt và không chứa khoảng trắng`
})
const formatErrorPassword = computed(() => {
  return props.type === 'password'
    ? `${props.label} phải tối thiểu ${$config.MIN_LENGTH_PASSWORD} kí tự, tối đa  ${$config.MAX_LENGTH_PASSWORD} kí tự`
    : ''
})
const formatErrorDuplicate = computed(() => {
  return props.errorDuplicate ? props.errorDuplicate : `${props.label} không trùng với tên đăng nhập.`
})
const formatErrorSpaceCharacter = computed(() => {
  return props.errorSpaceCharacter ? props.errorSpaceCharacter : `${props.label} không được chứa khoảng trắng.`
})

const onPrepare = (value: string) => {
  if (props.isUpperCase) {
    return value.toUpperCase()
  }
  return value
}

const validate = () => {
  $v.value.$touch()
  if (!$v.value.$invalid) {
    return props.modelValue
  }
}
const resetValidate = () => {
  $v.value.$reset()
  resetValue()
}

const onlyResetValidate = () => {
  $v.value.$reset()
}

const onBlur = () => {
  isFocus.value = false
  isShowError.value = true
  if (props.blurValidate) {
    $v.value.$touch()
  }
}
const onFocus = () => {
  isFocus.value = true
}
const togglePassword = () => {
  isShowPassword.value = !isShowPassword.value
  inputType.value = isShowPassword.value ? 'text' : 'password'
}
const resetValue = () => {
  currentValue.value = ''
}
const getMask = () => {
  if (!isEmpty(props.maskCustom)) {
    return props.maskCustom
  }
  if (props.type === 'password' || inputType.value === 'password') {
    return null
  }
  if (props.type === 'text' || inputType.value === 'text') {
    return String
  }
  if (inputType.value === 'number') {
    return Number
  }
  if (inputType.value === 'tel') {
    return /^\d+$/
  }
  if (inputType.value === 'email') {
    return /^\S*@?\S*$/
  }
  if (props.fullNameCharacter) {
    return /^[A-Za-z ]*$/
  }
  if (inputType.value === 'money') {
    return Number
  }
  return /^\w+$/
}

const onKeyPress = ($event: KeyboardEvent) => {
  emit('on-key-press', $event.keyCode)
}

const rules = {
  currentValue: {
    required: props.required ? required : true,
    maxLength: props.maxLength ? maxLength(props.maxLength) : true,
    minLength: props.minLength ? minLength(props.minLength) : true,
    specialCharacter: props.specialCharacter ? specialCharacter : true,
    usernameCharacter: props.usernameCharacter ? usernameCharacter : true,
    phoneCharacter: props.phoneCharacter ? phoneCharacter : true,
    startZero: props.startZero ? startZero : true,
    fullNameCharacter: props.fullNameCharacter ? fullNameCharacter : true,
    displayNameCharacter: props.displayNameCharacter ? displayNameCharacter : true,
    minValue: (value: number) => {
      const amount = $formatMarkToNumber(value.toString())
      return props.minValue ? amount >= props.minValue : true
    },
    maxValue: (value: number) => {
      const amount = $formatMarkToNumber(value.toString())
      return props.maxValue || props.maxValue === 0 ? amount <= props.maxValue : true
    },
    email: props.type === 'email' ? email : true,
    sameAs: (value: string) => {
      return props.sameAs ? value === props.sameAs : true
    },
    password: props.type === 'password' && props.allowValidatePassword ? validPassword : false,
    isDuplicate: (value: string) => {
      return props.isDuplicate ? props.isDuplicate.toLowerCase() !== (value?.toLowerCase() ?? '') : true
    },
    validSpaceCharacter: props.notAllowSpace ? validSpaceCharacter : true,
    validTrc20Wallet: props.trc20Wallet ? validTrc20Wallet : true,
    validErc20Wallet: props.erc20Wallet ? validErc20Wallet : true
  }
}
const $v = useVuelidate(rules, { currentValue })

watch(
  () => isFocus.value,
  () => {
    emit('input-focus', isFocus.value)
  }
)
defineExpose({ validate, resetValidate, focus, onlyResetValidate })
</script>
<script lang="ts">
export default {
  inheritAttrs: false
}
</script>
<style lang="scss" scoped src="~/assets/scss/components/common/base-input.scss" />
